import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_DATA = {
  filterdIdList: [],

  currentPage: 1,
  perPage: 10,
  totalRows: 0,
  // フィルタリング条件保存
  filter: {
    year: null,
    to_name: '',
    to_mail_address: '',
    from_user_name: '',
    title: '',
    type: null,
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_DATA),
  mutations: {
    setFilter(state, param) {
      Vue.set(state.filter, param.key, param.value);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    initFilter(state) {
      Vue.set(state.filter, 'year', null);
      Vue.set(state.filter, 'to_name', '');
      Vue.set(state.filter, 'to_mail_address', '');
      Vue.set(state.filter, 'from_user_name', '');
      Vue.set(state.filter, 'title', '');
      Vue.set(state.filter, 'type', null);
      state.currentPage = 1;
      state.perPage = 10;
      state.totalRows = 0;
    },
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.id;
      });
      state.filterdIdList = filterdIdList;
    },
  },
  actions: {
  },
};
