import Vue from 'vue';
import { cloneDeep } from 'lodash';
import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdList: [],
  selectedAppIdList: [],

  currentPage: 1,
  perPage: 10,
  totalRows: 0,
  // フィルタリング条件保存
  judgmentFilter: {
    year: moment().format('YYYY'),
    apptype: null,
    stage: null,
    pass: '',
    group: '',
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilter(state, param) {
      Vue.set(state.judgmentFilter, param.key, param.value);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.app_id;
      });
      state.filterdIdList = filterdIdList;
    },
    removeSelectedAppid(state, appId) {
      const index = state.selectedAppIdList.indexOf(appId);
      if (index !== -1) {
        state.selectedAppIdList.splice(index, 1);
      }
    },
    setSelectedAppid(state, list) {
      state.selectedAppIdList = list;
    },
    addSelectedAppid(state, appId) {
      if (state.selectedAppIdList.indexOf(appId) === -1) {
        state.selectedAppIdList.push(appId);
      }
    },
    allSelectedAppid(state) {
      state.selectedAppIdList = state.filterdIdList.slice();
    },
    initSelectedAppid(state) {
      state.selectedAppIdList = [];
    },
    initFilter(state) {
      Vue.set(state.judgmentFilter, 'year', null);
      Vue.set(state.judgmentFilter, 'apptype', null);
      Vue.set(state.judgmentFilter, 'stage', null);
      Vue.set(state.judgmentFilter, 'pass', '');
      Vue.set(state.judgmentFilter, 'group', '');
      state.currentPage = 1;
      state.perPage = 10;
      state.totalRows = 0;
    },

  },
  actions: {
  },
};
