// import Vue from 'vue';
import { cloneDeep } from 'lodash';

const INIT_OPTION = {
  id: '',
  label: '',
  input_type: '',
  prefix: '',
  suffix: '',
  parent_option_id: null,
  parent_choice_id: null,
  view_template: 1,
};

const INIT_FIELD = {
  // ページ-記入項目連関
  id: 1,
  field_id: 1,
  description: '',
  notice: '',
  is_required: false,
  // 記入項目マスタ
  type: 1,
  label: '',
  is_variable: false,
  input_number: 1,
  min_input_number: 1,
  max_input_number: 1,
  view_template: 1,
  options: [cloneDeep(INIT_OPTION)],
};

const INIT_PAGE = {
  id: 1,
  page: 1,
  name: '',
  fields: cloneDeep(INIT_FIELD),
};

export default {
  namespaced: true,
  state: {
    application_type_id: null,
    application_type_name: null,
    report_type_id: null,
    user_id: null,
    application_id: null,
    isCms: false,
    pages: [cloneDeep(INIT_PAGE)],
    hasChange: false,
    optionDataList: {},
    attachment_id: null,
  },
  mutations: {
    init(state) {
      // 初期化
      state.user_id = null;
      state.application_type_id = null;
      state.application_type_name = null;
      state.application_id = null;
      state.report_type_id = null;
      state.report_id = null;
      state.isCms = false;
      state.pages = [cloneDeep(INIT_PAGE)];
      state.hasChange = false;
      state.optionDataList = {};
      state.attachment_id = null;
    },
    setDbData(state, dbPages) {
      state.pages = [];
      state.optionDataList = {};
      dbPages.forEach((dbPage) => {
        state.pages.push(dbPage);
        dbPage.fields.forEach((field) => {
          const optionData = {};
          optionData.fieldId = field.field_id;
          optionData.fieldLabel = field.label;
          optionData.is_required = field.is_required;
          optionData.params = field.params;
          field.options.forEach((option) => {
            optionData.optionId = option.id;
            optionData.optionLabel = option.label;
            state.optionDataList[option.id] = Object.assign({}, optionData);
          });
        });
      });
    },
    setApplicationTypeId(state, value) {
      state.application_type_id = value;
    },
    setApplicationTypeName(state, value) {
      state.application_type_name = value;
    },
    setApplicationId(state, value) {
      state.application_id = value;
    },
    setReportTypeId(state, value) {
      state.report_type_id = value;
    },
    setReportId(state, value) {
      state.report_id = value;
    },
    setAttachmentId(state, value) {
      state.attachment_id = value;
    },
    setIsCms(state, value) {
      state.isCms = value;
    },
    setUserId(state, value) {
      state.user_id = value;
    },
    setHasChange(state, value) {
      state.hasChange = value;
    },
    // addPage(state) {
    //   state.pages.push(cloneDeep(INIT_PAGE));
    // },
    // removePage(state, index) {
    //   state.pages.splice(index, 1);
    // },
    // updateName(state, param) {
    //   Vue.set(state.pages[param.index], 'name', param.value);
    // },
  },
  actions: {
  },
};
