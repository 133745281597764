<template>
  <b-container fluid :class="(errMessage.length)?'errItem itemWrap':'itemWrap'">
    <b-row class="inputGroup">
      <b-col sm="4" class="labelWrap">
        <div class="badgeWrap">
          <div v-if="isRequired" class="mr-2 badge badge-required">必須</div>
        </div>
        <div class="labelText">
          <label>{{ label }}</label>
        </div>
      </b-col>
      <b-col class="textWrap year" sm="3">
        <b-form-input
          :placeholder="placeholders.year"
          :size="size"
          :maxLength="maxLength.year"
          v-model="date.year"
          @input="input"
        >
        </b-form-input>
      </b-col>
      <span>年</span>
      <b-col class="textWrap" sm="1">
        <b-form-select
          class="selectDate"
          v-model="date.month"
          :options="monthOptions"
          @change="input"
        ></b-form-select>
      </b-col>
      <span>月</span>
      <b-col class="textWrap" sm="1">
        <b-form-select
          class="selectDate"
          v-model="date.day"
          :options="dayOptions"
          @change="input"
        ></b-form-select>
      </b-col>
      <span>日</span>
    </b-row>
    <span class="error fw-3" v-if="errMessage.length">
      {{ errMessage[0] }}
    </span>
  </b-container>
</template>
<script>
import TimeOptionHelper from '@/modules/TimeSelectBoxOption';

export default {
  name: 'DateFormText',
  props: {
    label: {},
    value: {},
    errMessage: { default: () => [] },
    placeholders: {
      type: Object,
      default: () => ({
        year: '',
        month: '',
        day: '',
      }),
    },
    maxLength: {
      type: Object,
      default: () => ({
        year: 4,
        month: 2,
        day: 2,
      }),
    },
    size: { type: String, default: '' },
    isRequired: { type: Boolean, default: false },
  },
  data() {
    return {
      date: {
        year: '',
        month: '',
        day: '',
      },
      formtSet: null,
      monthOptions: [],
      dayOptions: [],
    };
  },
  created() {
    this.monthOptions = TimeOptionHelper.getMonthOptions();
    this.dayOptions = TimeOptionHelper.getDayOptions();

    if (this.value !== null) {
      const valueData = (this.value.indexOf('/') !== -1) ? this.value.split('/') : this.value.split('-');
      const dateKey = ['year', 'month', 'day'];
      valueData.map((test, key) => {
        this.date[dateKey[key]] = test;
        return true;
      });
    }
  },
  methods: {
    input() {
      if (!this.date.year && !this.date.month && !this.date.day) {
        this.$emit('input', null);
        return;
      }
      const separator = '/';
      const date = this.date.year + separator + this.date.month + separator + this.date.day;
      this.$emit('input', date);
    },
  },
};
</script>

<style scoped>
/* 共通Style */
* {
  color: #333333;
}
.itemWrap{
  margin:15px 0;
  width: 800px;
}
.inputGroup{
  align-items: center;
  margin:0 auto;
}
.selectDate{
  width: 90px;
  height: 50px;
}
label,
.labelWrap {
  width: 180px;
  max-width: 237px;
  font-weight: bold;
  white-space:pre-line;
}
.year input[type="text"]{
  width: 120px;
}
input[type="text"]{
  width: 90px;
  height: 50px;
  margin: 0;
}
.labelWrap {
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.labelWrap .badgeWrap,
.labelWrap .labelText{
  display: inline-block;
}
.labelWrap .badgeWrap div{
  vertical-align: middle;
}
label{
  align-items:center;
  margin:0;
}
label{
  text-align: left;
}
span.error{
  padding-left: 31.5%;
  text-align: left;
}
.textWrap{
  max-width: 150px;
  display: table;
  padding-right: 10px;
  padding-left: 20px;
}
.textWrap:not(.year){
  padding-left: 10px;
}
.errItem input{
  border:solid 1px #dc3545;
}
.error{
  font-size: 12.8px;
  color: #dc3545;
  display: block;
  width: 582px;
  margin-left: 15px;
  margin-top: 3px;
}
</style>
