import Vue from 'vue';
import moment from 'moment';
import { cloneDeep } from 'lodash';

const INIT_USER_INFO = {
  // ユーザー情報
  inputData: {
    sei: '',
    mei: '',
    kanaSei: '',
    kanaMei: '',
    barth: null,
    gender: null,
    position: null,
    degree: null,
    postalCode: null,
    address: null,
    institutionName: null,
    institutionName2: null,
    institutionName3: null,
    institutionPostalCode: null,
    institutionAddress: null,
    institutionStation: null,
    institutionTel: null,
    institutionFax: null,
    alternativeName: null,
    alternativeTel: null,
    alternativeInstitution: null,
  },
  errors: [],
  initData: [],
  isChange: false,
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_USER_INFO),
  mutations: {
    setDbData(state, user) {
      if (user) {
        Vue.set(state.inputData, 'sei', user.sei);
        Vue.set(state.inputData, 'mei', user.mei);
        Vue.set(state.inputData, 'kanaSei', user.kana_sei);
        Vue.set(state.inputData, 'kanaMei', user.kana_mei);
        Vue.set(state.inputData, 'barth', moment(user.barth).format('YYYY/MM/DD'));
        Vue.set(state.inputData, 'gender', user.gender);
        Vue.set(state.inputData, 'position', user.position);
        Vue.set(state.inputData, 'degree', user.degree);
        Vue.set(state.inputData, 'postalCode', user.postal_code);
        Vue.set(state.inputData, 'address', user.address);
        Vue.set(state.inputData, 'institutionName', user.institution_name);
        Vue.set(state.inputData, 'institutionName2', user.institution_name_2);
        Vue.set(state.inputData, 'institutionName3', user.institution_name_3);
        Vue.set(state.inputData, 'institutionPostalCode', user.institution_postal_code);
        Vue.set(state.inputData, 'institutionAddress', user.institution_address);
        Vue.set(state.inputData, 'institutionStation', user.institution_station);
        Vue.set(state.inputData, 'institutionTel', user.institution_tel);
        Vue.set(state.inputData, 'institutionFax', user.institution_fax);
        Vue.set(state.inputData, 'alternativeName', user.alternative_name);
        Vue.set(state.inputData, 'alternativeTel', user.alternative_tel);
        Vue.set(state.inputData, 'alternativeInstitution', user.alternative_institution);
        state.initData = JSON.parse(JSON.stringify(state.inputData));
      }
    },
    // ユーザー情報
    updateSei(state, value) {
      Vue.set(state.inputData, 'sei', value);
    },
    updateMei(state, value) {
      Vue.set(state.inputData, 'mei', value);
    },
    updateKanaSei(state, value) {
      Vue.set(state.inputData, 'kanaSei', value);
    },
    updateKanaMei(state, value) {
      Vue.set(state.inputData, 'kanaMei', value);
    },
    updateBarth(state, value) {
      Vue.set(state.inputData, 'barth', value);
    },
    updateGender(state, value) {
      Vue.set(state.inputData, 'gender', value);
    },
    updatePosition(state, value) {
      Vue.set(state.inputData, 'position', value);
    },
    updateDegree(state, value) {
      Vue.set(state.inputData, 'degree', value);
    },
    updatePostalCode(state, value) {
      Vue.set(state.inputData, 'postalCode', value);
    },
    updateAddress(state, value) {
      Vue.set(state.inputData, 'address', value);
    },
    updateInstitutionName(state, value) {
      Vue.set(state.inputData, 'institutionName', value);
    },
    updateInstitutionName2(state, value) {
      Vue.set(state.inputData, 'institutionName2', value);
    },
    updateInstitutionName3(state, value) {
      Vue.set(state.inputData, 'institutionName3', value);
    },
    updateInstitutionPostalCode(state, value) {
      Vue.set(state.inputData, 'institutionPostalCode', value);
    },
    updateInstitutionAddress(state, value) {
      Vue.set(state.inputData, 'institutionAddress', value);
    },
    updateInstitutionStation(state, value) {
      Vue.set(state.inputData, 'institutionStation', value);
    },
    updateInstitutionTel(state, value) {
      Vue.set(state.inputData, 'institutionTel', value);
    },
    updateInstitutionFax(state, value) {
      Vue.set(state.inputData, 'institutionFax', value);
    },
    updateAlternativeName(state, value) {
      Vue.set(state.inputData, 'alternativeName', value);
    },
    updateAlternativeTel(state, value) {
      Vue.set(state.inputData, 'alternativeTel', value);
    },
    updateAlternativeInstitution(state, value) {
      Vue.set(state.inputData, 'alternativeInstitution', value);
    },
    setSavedData(state) {
      state.initData = JSON.parse(JSON.stringify(state.inputData));
    },
    // エラー関連
    setErrorMsg(state, errors) {
      state.errors = errors;
    },
    deleteErrMsg(state, target) {
      Vue.delete(state.errors, target);
    },
    resetErrorMsg(state) {
      state.errors = [];
    },
    changeCheck(state) {
      const nowData = JSON.stringify(state.inputData);
      const initData = JSON.stringify(state.initData);
      state.isChange = (nowData !== initData);
    },
    resetInputData(state) {
      state.inputData = JSON.parse(JSON.stringify(state.initData));
    },
    initInputData(state) {
      state.inputData = {
        sei: '',
        mei: '',
        kanaSei: '',
        kanaMei: '',
        barth: null,
        gender: null,
        position: null,
        degree: null,
        postalCode: null,
        address: null,
        institutionName: null,
        institutionName2: null,
        institutionName3: null,
        institutionPostalCode: null,
        institutionAddress: null,
        institutionStation: null,
        institutionTel: null,
        institutionFax: null,
        alternativeName: null,
        alternativeTel: null,
        alternativeInstitution: null,
      };
    },
  },
  actions: {
  },
};
