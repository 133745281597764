<template>
  <div class="menuLabel flex flexRight flexMiddle">
    <b-nav-item
      @click="goInit('/cms/user/cms', 'cmsUserSearch')"
      :class="menuName === 'cmsUser' ? 'activeNav' : ''"
      v-if="group||secretariat">管理者</b-nav-item>
    <b-nav-item to="/cms/form/edit"
      :class="menuName === 'cmsForm' ? 'activeNav' : ''"
      v-if="secretariat">申請フォーム</b-nav-item>
    <b-nav-item to="/cms/user/gas/list"
      :class="menuName === 'cmsUserGas' ? 'activeNav' : ''"
      v-if="secretariat">ユーザー</b-nav-item>
    <b-nav-item-dropdown text="申請"
      :class="menuName === 'cmsApplication' ? 'activeNav' : ''"
      v-if="secretariat || adoption">
      <b-dropdown-item
        @click="goInit('/cms/applications/list', 'cmsApplicationSearch')"
        v-if="secretariat">申請管理</b-dropdown-item>
      <b-dropdown-item
        to="/cms/applications/assign/list"
        v-if="secretariat">選考評価担当割当</b-dropdown-item>
      <b-dropdown-item
        @click="goInit('/cms/applications/judgment/list', 'cmsJudgmentSearch')"
        v-if="adoption">合否設定</b-dropdown-item>
      <b-dropdown-item
        @click="goInit('/cms/applications/attachment/list', 'cmsAttachmentSearch')"
        v-if="adoption">追加資料管理</b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item
      @click="goInit('/cms/evaluation/list', 'cmsEvaluationSearch')"
      :class="menuName === 'cmsEvaluation' ? 'activeNav' : ''"
      v-if="evaluation">選考評価</b-nav-item>

    <b-nav-item
      @click="goInit('/cms/reports/list', 'cmsReportSearch')"
      :class="menuName === 'cmsReport' ? 'activeNav' : ''"
      v-if="secretariat">報告管理</b-nav-item>

    <b-nav-item to="/cms/news"
      :class="menuName === 'cmsNews' ? 'activeNav' : ''"
      v-if="secretariat">お知らせ</b-nav-item>

    <b-nav-item-dropdown text="履歴"
      :class="menuName === 'cmsRecord' ? 'activeNav' : ''"
      v-if="secretariat">
      <b-dropdown-item
        @click="goInit('/cms/record/mail/list', 'cmsRecordMailSearch')"
        >メール送信履歴</b-dropdown-item>
      <b-dropdown-item to="/cms/applications/history/list"
        >申請データ変更履歴</b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item
      :class="menuName === 'cmsSetting' ? 'activeNav' : ''"
      to="/cms/setting">設定</b-nav-item>
    <span
      class="logout px-3"
      @click="kickLogout"
      >ログアウト</span>
  </div>
</template>

<script>
import CONST_AUTH from '@/constants/auth';

export default {
  name: 'CmsHeader',
  props: {
    menuName: { type: String, default: '' },
    goInit: { type: Function },
  },
  computed: {
    // CMSグループ管理者
    group() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.GROUP);
    },
    // コンテンツ管理者
    content() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.CONTENT);
    },
    // 事務局
    secretariat() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.SECRETARIAT);
    },
    // 評価担当者
    evaluation() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.EVALUATION);
    },
    // 採択担当者
    adoption() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.ADOPTION);
    },
  },
  methods: {
    kickLogout() {
      this.$emit('kickLogout');
    },
  },
};
</script>

<style scoped>
  .logout {
    border: solid;
    border-width: 0 0 0 1px;
    border-color: #ccc;
  }

  .logout:hover {
    cursor: pointer;
  }

  .menuLabel>li{
    list-style: none;
  }

</style>
