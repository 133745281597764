
const AUTH = {
  GROUP: 1,
  CONTENT: 2,
  SECRETARIAT: 3,
  EVALUATION: 4,
  ADOPTION: 5,
};

const AUTH_TEXT = {
  1: 'グループ責任者',
  2: 'コンテンツ管理者',
  3: '事務局ユーザー',
  4: '選考評価担当者',
  5: '合否決定者',
};

const USER_TYPE = {
  GAS: 1,
  CMS: 2,
  SYS: 9,
};

const USER_META = {
  1: ['gas'],
  2: ['cms'],
  9: ['gas', 'cms'],
};

export default {
  AUTH,
  AUTH_TEXT,
  USER_TYPE,
  USER_META,
};
