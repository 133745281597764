<template>
  <div class="menuLabel flex flexRight flexMiddle">
    <b-nav-item
      :class="menuName === 'record' ? 'activeNav' : ''"
      to="/record"
      right>申請履歴</b-nav-item>
    <b-nav-item-dropdown text="設定"
      :class="menuName === 'setting' ? 'activeNav' : ''"
      :toggle-class="/gasMy/.test($route.name) ? 'active' : ''">
      <b-dropdown-item to="/myInfo">ユーザー情報</b-dropdown-item>
      <b-dropdown-item to="/myAuth">認証情報</b-dropdown-item>
    </b-nav-item-dropdown>
    <span
      @click="kickLogout"
      class="logout px-3"
      >ログアウト</span>
  </div>
</template>

<script>
export default {
  name: 'GasHeader',
  props: {
    menuName: { type: String, default: '' },
  },
  methods: {
    kickLogout() {
      this.$emit('kickLogout');
    },
  },
};
</script>

<style scoped>
  .logout {
    border: solid;
    border-width: 0 0 0 1px;
    border-color: #ccc;
  }

  .logout:hover {
    cursor: pointer;
  }

  .menuLabel>li{
    list-style: none;
  }

</style>
