import Vue from 'vue';
import Vuex from 'vuex';

import '@babel/polyfill';

import { ValidationProvider, localize } from 'vee-validate/dist/vee-validate.full.esm';
import { ValidationObserver } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';

import BootstrapVue from 'bootstrap-vue';
import Unicon from 'vue-unicons';

// 使用するアイコン読み込み
import {
  uniPlusCircle,
  uniCalendarAlt,
  uniCaretRight,
  uniFileDownloadAlt,
} from 'vue-unicons/src/icons';

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import VueSanitize from 'vue-sanitize';

// components file
import formText from '@/components/parts/FormText.vue';
import FormTextarea from '@/components/parts/FormTextarea.vue';
import FormSelect from '@/components/parts/FormSelect.vue';
import nameFormText from '@/components/parts/NameFormText.vue';
import calendarBox from '@/components/parts/CalendarBox.vue';
import formCheckBox from '@/components/parts/FormCheckBox.vue';
import calendarDatetimeBox from '@/components/parts/CalendarDatetimeBox.vue';
import formBarth from '@/components/parts/FormBarth.vue';
import showDataText from '@/components/parts/ShowDataText.vue';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// グローバルメソッドのインポート
import dialog from '@/mixin/dialog';

Vue.use(Vuex);
Vue.use(BootstrapVue);

Unicon.add([uniPlusCircle, uniCalendarAlt, uniCaretRight, uniFileDownloadAlt]);
Vue.use(Unicon);

Vue.use(PerfectScrollbar);
Vue.use(VueSanitize);

Vue.config.productionTip = false;

// パーツの読込
Vue.component('v-form-text', formText);
Vue.component('v-form-textarea', FormTextarea);
Vue.component('v-form-select', FormSelect);
Vue.component('v-form-name', nameFormText);
Vue.component('v-form-calendar', calendarBox);
Vue.component('v-form-checkbox', formCheckBox);
Vue.component('v-form-calendar-datetime', calendarDatetimeBox);
Vue.component('v-form-barth', formBarth);
Vue.component('v-show-data', showDataText);

// バリデーションの読込
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
localize('ja', ja);

// グローバルメソッドのグローバルミックスイン
Vue.mixin(dialog);

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App,
  },
  render: h => h(App),
}).$mount('#app');
