import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdList: [],
  selectedAppIdList: [],

  currentPage: 1,
  perPage: 10,
  totalRows: 0,
  // フィルタリング条件保存
  search: {
    name: '',
    series: '',
    year: '',
    code: '',
    status: '',
    institution_name: '',
    division: '',
    keyword: '',
    group: '',
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilter(state, param) {
      Vue.set(state.search, param.key, param.value);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    initFilter(state) {
      Vue.set(state.search, 'name', '');
      Vue.set(state.search, 'series', '');
      Vue.set(state.search, 'year', '');
      Vue.set(state.search, 'code', '');
      Vue.set(state.search, 'status', '');
      Vue.set(state.search, 'institution_name', '');
      Vue.set(state.search, 'division', '');
      Vue.set(state.search, 'keyword', '');
      Vue.set(state.search, 'group', '');
      state.currentPage = 1;
      state.perPage = 10;
      state.totalRows = 0;
    },
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.id;
      });
      state.filterdIdList = filterdIdList;
    },
    removeSelectedAppid(state, appId) {
      const index = state.selectedAppIdList.indexOf(appId);
      if (index !== -1) {
        state.selectedAppIdList.splice(index, 1);
      }
    },
    addSelectedAppid(state, appId) {
      if (state.selectedAppIdList.indexOf(appId) === -1) {
        state.selectedAppIdList.push(appId);
      }
    },
    allSelectedAppid(state) {
      state.selectedAppIdList = state.filterdIdList.slice();
    },
    initSelectedAppid(state) {
      state.selectedAppIdList = [];
    },
  },
  actions: {
  },
};
