export default {
  namespaced: true,
  state: {
    view: 'public',
    error: false,
    loading: false,
    loadStockList: [],
    waiting: false,
    directUrl: '',
  },
  mutations: {
    create(state, view) {
      state.view = view;
    },
    destroy(state) {
      state.view = 'public';
    },
    onError(state) {
      state.error = true;
    },
    offError(state) {
      state.error = false;
    },
    onLoading(state, name) {
      state.loadStockList.push(name);
      state.loading = true;
    },
    offLoading(state, name) {
      const index = state.loadStockList.indexOf(name);
      if (index !== -1) {
        state.loadStockList.splice(index, 1);
      }
      if (state.loadStockList.length === 0) {
        state.loading = false;
      }
    },
    onWaiting(state) {
      state.waiting = true;
    },
    offWaiting(state) {
      state.waiting = false;
    },
    setDirectUrl(state, value) {
      state.directUrl = value;
    },
  },
  // 非同期処理が使える
  actions: {
    create(context, view) {
      context.commit('create', view);
    },
    destroy(context) {
      context.commit('destroy');
    },
    onError(context) {
      context.commit('onError');
    },
    offError(context) {
      context.commit('offError');
    },
    onLoading(context, name = '__default') {
      context.commit('onLoading', name);
    },
    offLoading(context, name = '__default') {
      context.commit('offLoading', name);
    },
    onWaiting(context) {
      context.commit('onWaiting');
    },
    offWaiting(context) {
      context.commit('offWaiting');
    },
  },
};
