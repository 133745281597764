import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdsList: [],
  selectedAppIdList: [],

  currentPage: 1,
  perPage: 10,
  totalRows: 0,
  // フィルタリング条件保存
  filter: {
    year: null,
    apptype: null,
    stage: null,
    status: null,
    prepass: 1,
    failure: false,
    all: false,
    marker: null,
  },
  // 評価用
  selectedMarkerName: '',
  evalItems: {},
  // 一覧の表示用
  allHeaderItems: [],
  selectedHeaderItems: [],
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    makeAllHeaderItems(state) {
      const header = [
        { key: 'institution_name', label: '所属', sortable: true, class: '' },
        { key: 'position', label: '職位', sortable: true },
        { key: 'age', label: '年齢', sortable: true, class: 'short-th' },
        { key: 'theme', label: '研究テーマ', sortable: true, class: 'ellipsis-td' },
        { key: 'dai_kubun', label: '大区分', sortable: true, class: 'short-th' },
        { key: 'keyword', label: 'キーワード', sortable: true },
      ];
      if (state.evalItems[1]) {
        state.evalItems[1].forEach((item) => {
          header.push({ key: item.label, label: item.label, sortable: true, class: 'short-th' });
        });
      }
      header.push({ key: 'overall_score', label: '総合評価', sortable: true, class: 'short-th' });
      header.push({ key: 'comment', label: 'コメント', sortable: true, class: 'ellipsis-td' });
      header.push({ key: 'score', label: '得点', sortable: true, class: 'short-th' });
      state.allHeaderItems = header;
    },
    setFilter(state, param) {
      Vue.set(state.filter, param.key, param.value);
    },
    setSelectedMarkerName(state, value) {
      state.selectedMarkerName = value;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    setEvalItems(state, value) {
      state.evalItems = value;
    },
    setFilterdIdsList(state, filterdData) {
      const filterdIdsList = filterdData.map((data) => {
        return { appId: data.app_id, stageId: data.stage_id };
      });
      state.filterdIdsList = filterdIdsList;
    },
    initFilter(state) {
      Vue.set(state.filter, 'year', null);
      Vue.set(state.filter, 'apptype', null);
      Vue.set(state.filter, 'stage', null);
      Vue.set(state.filter, 'status', null);
      Vue.set(state.filter, 'prepass', 1);
      Vue.set(state.filter, 'failure', false);
      Vue.set(state.filter, 'all', false);
      Vue.set(state.filter, 'marker', null);
      state.selectedMarkerName = '';
      state.currentPage = 1;
      state.perPage = 10;
      state.totalRows = 0;
      state.evalItems = {};
    },
    removeSelectedAppid(state, appId) {
      const index = state.selectedAppIdList.indexOf(appId);
      if (index !== -1) {
        state.selectedAppIdList.splice(index, 1);
      }
    },
    addSelectedAppid(state, appId) {
      if (state.selectedAppIdList.indexOf(appId) === -1) {
        state.selectedAppIdList.push(appId);
      }
    },
    allSelectedAppid(state) {
      state.selectedAppIdList = state.filterdIdsList.map((ids) => {
        return ids.appId;
      });
    },
    initSelectedAppid(state) {
      state.selectedAppIdList = [];
    },
    loadHeaderItems(state) {
      const jsonStorage = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_SETTING_KEY);
      const storage = jsonStorage !== null ? JSON.parse(jsonStorage) : {};
      if (storage.EvalHeaderItems) {
        state.selectedHeaderItems = storage.EvalHeaderItems;
      } else {
        state.selectedHeaderItems = ['institution_name', 'position', 'theme', 'score'];
      }
    },
    setHeaderItems(state, value) {
      state.selectedHeaderItems = value;
      const jsonStorage = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_SETTING_KEY);
      const storage = jsonStorage !== null ? JSON.parse(jsonStorage) : {};
      storage.EvalHeaderItems = value;
      localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_SETTING_KEY,
        JSON.stringify(storage));
    },
  },
  actions: {
  },
};
