import Vue from 'vue';
import Vuex from 'vuex';

// 権限、ページ制御等共通処理
import auth from '@/store/modules/auth';
import page from '@/store/modules/page';
import cmsUserSetting from '@/store/modules/cmsUserSetting';

// CMSコンテンツ管理
import validation from '@/store/modules/validationRules';
import cmsContentsField from '@/store/modules/cms/contents/field';
import cmsContentsApptype from '@/store/modules/cms/contents/apptype';
import cmsContentsApptypePage from '@/store/modules/cms/contents/apptypePage';

// CMS管理者
import cmsUser from '@/store/modules/cms/user/cmsUser';
import cmsSetting from '@/store/modules/cms/user/cmsSetting';
import cmsUserSearch from '@/store/modules/cms/user/cmsUserSearch';

// CMSお知らせ
import cmsNews from '@/store/modules/cms/news/news';

// GASユーザー情報
import userInfo from '@/store/modules/userInfo';
import userInfoForm from '@/store/modules/userInfoForm';
import authInfo from '@/store/modules/gas/setting/authInfo';
// 一般画面申請・報告
import gasApplicationForm from '@/store/modules/gas/form/applicationForm';
import gasApplicationInput from '@/store/modules/gas/form/applicationInput';

// 管理画面申請
import cmsApplicationSearch from '@/store/modules/cms/applications/search';
import cmsApplicationControl from '@/store/modules/cms/applications/control';

// 管理画面報告
import cmsReportSearch from '@/store/modules/cms/reports/search';
import cmsReportControl from '@/store/modules/cms/reports/control';

// 選考評価
import cmsEvaluationSearch from '@/store/modules/cms/evaluation/search';

// 合否
import cmsJudgmentSearch from '@/store/modules/cms/applications/judgeSearch';

// 申請フォーム管理
import cmsForm from '@/store/modules/cms/form/form';

// 管理画面 履歴 メール
import cmsRecordMailSearch from '@/store/modules/cms/record/mailSearch';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    page,
    validation,
    cmsUserSetting,
    cmsContentsField,
    authInfo,
    userInfo,
    userInfoForm,
    cmsUser,
    cmsSetting,
    cmsUserSearch,
    cmsNews,
    cmsContentsApptype,
    cmsContentsApptypePage,
    gasApplicationForm,
    gasApplicationInput,
    cmsApplicationSearch,
    cmsApplicationControl,
    cmsEvaluationSearch,
    cmsJudgmentSearch,
    cmsForm,
    cmsReportSearch,
    cmsReportControl,
    cmsRecordMailSearch,
  },
});
