import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_DATA = {
  currentPage: 1,
  perPage: 10,
  totalRows: 0,
  // フィルタリング条件保存
  filter: {
    name: '',
    authes: '',
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_DATA),
  mutations: {
    setFilter(state, param) {
      Vue.set(state.filter, param.key, param.value);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    initFilter(state) {
      Vue.set(state.filter, 'name', '');
      Vue.set(state.filter, 'authes', '');
      state.currentPage = 1;
      state.perPage = 10;
      // ここは位置段階の検索なので、総数の初期化を行わない
      // state.totalRows = 0;
    },
  },
  actions: {
  },
};
