import Vue from 'vue';
import { cloneDeep } from 'lodash';

function _searchTargetIndex(array, param) {
  let foundIndex;
  array.find((input, index) => {
    const sameField = input.field_id === param.field_id;
    const sameOption = input.option_id === param.option_id;
    const sameAnswerNo = input.answer_no === param.answer_no;
    if (sameField && sameOption && sameAnswerNo) {
      foundIndex = index;
    }
    return sameField && sameOption && sameAnswerNo;
  });
  return foundIndex;
}

function _getAnswer(array, param) {
  return array.find((input) => {
    const sameField = input.field_id === param.field_id;
    const sameOption = input.option_id === param.option_id;
    const sameAnswerNo = input.answer_no === param.answer_no;
    return sameField && sameOption && sameAnswerNo;
  });
}

function _getCheckBoxAnswer(array, param) {
  const answers = [];
  array.forEach((input) => {
    const sameField = input.field_id === param.field_id;
    const sameOption = input.option_id === param.option_id;
    if (sameField && sameOption) {
      answers.push(String(input.field_answer_id));
    }
  });
  return answers;
}

/**
 * field_id: maxAnswerNumの形のObjectを返す
 * @param {*} array
 */
function getMaxAnswerNums(answers) {
  const maxAnswerNums = {};
  answers.forEach((answer) => {
    const nowMaxAnswer = maxAnswerNums[answer.field_id] || 0;
    if (nowMaxAnswer < answer.answer_no) {
      maxAnswerNums[answer.field_id] = answer.answer_no;
    }
  });
  return maxAnswerNums;
}

const INIT_INPUTS = {
  page_field_id: null,
  field_id: null,
  option_id: null,
  answer_no: null,
  field_answer_id: null, // 選択式の時の解答欄
  field_answer_text: '', // 入力式の時の解答欄
  checkbox_answers: [],
  uploadedFileKey: '', // ファイルの時のキー
  dbFileName: null,
  do_delete_file: false,
  errMessages: [],
};

export default {
  namespaced: true,
  state: {
    inputs: [cloneDeep(INIT_INPUTS)],
    files: {},
    maxAnswerNums: {},
    code: '',
  },
  mutations: {
    setDbData(state, dbData) {
      // 初期化
      state.inputs = [];
      state.files = {};
      state.maxAnswerNums = {};
      state.code = '';
      const { pages: dbPages, answers } = dbData;
      if (answers !== null) {
        state.maxAnswerNums = getMaxAnswerNums(answers);
      }
      // ページのループ
      dbPages.forEach((dbPage) => {
        // フィールドのループ
        dbPage.fields.forEach((field) => {
          // 通常はinput_numberの設定値だけ取得する
          // ただしすでに登録されているデータがそれより多い場合は、データ分入れ物を作る
          let inputNum = field.input_number;

          // 新規登録の場合はここでmaxInputNumsを、input_numberを元に作る
          if (!answers) {
            state.maxAnswerNums[field.field_id] = field.input_number;
          } else if (inputNum < state.maxAnswerNums[field.field_id]) {
            // 編集登録の場合は、登録済みの回答数が初期値より多ければ、登録済みの方を初期値にする。
            inputNum = state.maxAnswerNums[field.field_id];
          }
          // オプションのループ
          field.options.forEach((option) => {
            const input = {
              field_id: field.field_id,
              option_id: option.id,
              answer_no: null,
              field_answer_id: null,
              field_answer_text: '',
              checkbox_answers: [],
              uploadedFileKey: '',
              dbFileName: null,
              do_delete_file: false,
              errMessages: [],
            };
            // チェックボックス以外
            if (option.input_type !== 7) {
              for (let answerNo = 1; answerNo <= inputNum; answerNo += 1) {
                input.answer_no = answerNo;
                // field_id-option_id-answer_noの一意キーを作成
                input.id = `${field.field_id}-${option.id}-${answerNo}`;
                if (answers !== null) {
                  const dbInput = _getAnswer(answers, input);
                  if (typeof dbInput !== 'undefined') {
                    input.field_answer_id = dbInput.field_answer_id;
                    input.field_answer_text = dbInput.field_answer_text;
                    input.checkbox_answers = dbInput.checkbox_answers || [];
                    input.dbFileName = dbInput.file_path;
                  }
                }
                state.inputs.push(cloneDeep(input));
              }
            } else {
              // チェックボックスのときは、複数の回答を1回答番号の中に配列で収める
              input.answer_no = 1;
              input.id = `${field.field_id}-${option.id}-1`;
              input.checkbox_answers = [];
              if (answers !== null) {
                input.checkbox_answers = _getCheckBoxAnswer(answers, input);
              }
              state.inputs.push(cloneDeep(input));
            }
          });
        });
      });
    },
    resetValue(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const target = state.inputs[foundIndex];
      Vue.set(target, 'field_answer_text', '');
      Vue.set(target, 'field_answer_id', null);
      Vue.set(target, 'checkbox_answers', []);
    },
    setText(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const target = state.inputs[foundIndex];
      Vue.set(target, 'field_answer_text', param.value);
    },
    setSelect(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const target = state.inputs[foundIndex];
      Vue.set(target, 'field_answer_id', param.value);
    },
    setFile(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const key = `file_${foundIndex}`;
      state.inputs[foundIndex].uploadedFileKey = key;
      Vue.set(state.files, key, param.file);
    },
    addInputs(state, params) {
      params.forEach((param) => {
        const input = {
          field_id: param.field_id,
          option_id: param.option_id,
          answer_no: param.answer_no,
          field_answer_id: null,
          field_answer_text: '',
          checkbox_answers: [],
          uploadedFileKey: '',
          dbFileName: null,
          do_delete_file: false,
          errMessages: [],
        };
        state.inputs.push(input);
      });
    },
    removeInputs(state, param) {
      let newInputs = cloneDeep(state.inputs);
      newInputs = newInputs.filter((input) => {
        const isSameField = input.field_id === param.fieldId;
        const isSameLine = input.answer_no === param.line;
        return !(isSameField && isSameLine);
      });
      newInputs = newInputs.map((input) => {
        const reduceInput = input;
        const isSameField = input.field_id === param.fieldId;
        if (isSameField && input.answer_no > param.line) {
          reduceInput.answer_no -= 1;
        }
        return reduceInput;
      });
      state.inputs = newInputs;
    },
    toggleDoDeleteFile(state, input) {
      const doDeleteFile = _getAnswer(state.inputs, input).do_delete_file;
      const index = _searchTargetIndex(state.inputs, input);
      Vue.set(state.inputs[index], 'do_delete_file', !doDeleteFile);
    },
    initErrMessages(state) {
      state.inputs.forEach((input, index) => {
        state.inputs[index].errMessages = [];
      });
    },
    setErrMessages(state, errMessages) {
      state.inputs.forEach((input, index) => {
        state.inputs[index].errMessages = [];
        if (errMessages[index]) {
          state.inputs[index].errMessages = errMessages[index];
        }
      });
    },
    setCode(state, value) {
      state.code = value;
    },
    setMaxAnswerNums(state, param) {
      Vue.set(state.maxAnswerNums, param.fieldId, param.newLineNum);
    },
    // addPage(state) {
    //   state.pages.push(cloneDeep(INIT_PAGE));
    // },
    // removePage(state, index) {
    //   state.pages.splice(index, 1);
    // },
    // updateName(state, param) {
    //   Vue.set(state.pages[param.index], 'name', param.value);
    // },
  },
  actions: {
  },
};
