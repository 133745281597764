import Vue from 'vue';
import VueRouter from 'vue-router';

import Store from '@/store/index';

import Auth from '@/constants/auth';

Vue.use(VueRouter);

const routes = [
  // public
  {
    path: '/user/register/initMail',
    name: 'gasRegisterInit',
    component: () => import('../views/RegisterInit.vue'),
    meta: {
      isPublic: true,
      page: 'public',
    },
  },
  {
    path: '/register/user/form/:uuid',
    name: 'gasRegisterForm',
    component: () => import('../views/RegisterForm.vue'),
    meta: {
      isPublic: true,
      page: 'public',
    },
  },
  {
    path: '/login',
    name: 'gasLogin',
    component: () => import('../views/GasLogin.vue'),
    meta: {
      isPublic: true,
      page: 'public',
    },
  },
  {
    path: '/cms/login',
    name: 'cmsLogin',
    component: () => import('../views/CmsLogin.vue'),
    meta: {
      isPublic: true,
      page: 'public',
    },
  },
  {
    path: '/identify/password/recover',
    name: 'passwordRecover',
    component: () => import('../views/passwordRecover.vue'),
    meta: {
      isPublic: true,
      page: 'public',
      back: 'gasLogin',
    },
  },
  {
    path: '/cms/identify/password/recover',
    name: 'cmsPasswordRecover',
    component: () => import('../views/passwordRecover.vue'),
    meta: {
      isPublic: true,
      page: 'public',
      back: 'cmsLogin',
    },
  },
  /*
  |--------------------------------------------------------------------------
  | 一般画面
  |--------------------------------------------------------------------------
  */
  {
    path: '/top',
    name: 'gasTop',
    component: () => import('../views/gas/Top.vue'),
    meta: {
      page: 'gas',
    },
  },
  {
    path: '/myInfo',
    name: 'gasMyParentInfo',
    component: () => import('../views/gas/MyInfo.vue'),
    meta: {
      page: 'gas',
      menu: 'setting',
    },
  },
  {
    path: '/myAuth',
    name: 'gasMyAuth',
    component: () => import('../views/gas/MyAuth.vue'),
    meta: {
      page: 'gas',
      menu: 'setting',
    },
  },
  {
    path: '/record',
    name: 'gasRecord',
    component: () => import('../views/gas/Record.vue'),
    meta: {
      page: 'gas',
      menu: 'record',
    },
  },
  // 申請ページ
  {
    path: '/application/:apptypeId',
    name: 'gasApplication',
    component: () => import('../views/gas/application/Init.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 申請フォーム
  {
    path: '/application/form/:apptypeId',
    name: 'gasApplicationForm',
    component: () => import('../views/gas/application/Form.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 申請終了
  {
    path: '/application/end',
    name: 'gasApplicationEnd',
    component: () => import('../views/gas/application/End.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 報告
  {
    path: '/report/:reptypeId',
    name: 'gasReport',
    component: () => import('../views/gas/report/Init.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 報告フォーム
  {
    path: '/report/form/:reptypeId',
    name: 'gasReportForm',
    component: () => import('../views/gas/report/Form.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 報告終了
  {
    path: '/report/end',
    name: 'gasReportEnd',
    component: () => import('../views/gas/report/End.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 追加資料フォーム
  {
    path: '/attachment/form/:apptypeId',
    name: 'gasAttachmentForm',
    component: () => import('../views/gas/attachment/Form.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 追加資料終了
  {
    path: '/attachment/end',
    name: 'gasAttachmentEnd',
    component: () => import('../views/gas/attachment/End.vue'),
    meta: {
      page: 'gas',
    },
  },
  /*
  |--------------------------------------------------------------------------
  | 管理画面
  |--------------------------------------------------------------------------
  */
  {
    path: '/cms/top',
    name: 'cmsTop',
    component: () => import('../views/cms/Top.vue'),
    meta: {
      page: 'cms',
    },
  },
  // 記入項目管理
  {
    path: '/cms/contents/fields',
    name: 'cmsContentsFiledsList',
    component: () => import('../views/cms/contents/fields/List.vue'),
    meta: {
      page: 'cms',
    },
  },
  {
    path: '/cms/contents/fields/create',
    name: 'cmsContentsFiledsCreate',
    component: () => import('../views/cms/contents/fields/Edit.vue'),
    meta: {
      page: 'cms',
    },
  },
  {
    path: '/cms/contents/fields/edit/:field_id',
    name: 'cmsContentsFiledsEdit',
    component: () => import('../views/cms/contents/fields/Edit.vue'),
    meta: {
      page: 'cms',
    },
  },
  // CMSユーザー管理
  {
    path: '/cms/user/cms',
    name: 'cmsUser',
    component: () => import('../views/cms/user/cms/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUser',
    },
  },
  {
    path: '/cms/user/cms/create',
    name: 'cmsUserCreate',
    component: () => import('../views/cms/user/cms/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUser',
    },
  },
  {
    path: '/cms/user/cms/:cms_user_id',
    name: 'cmsUserEdit',
    component: () => import('../views/cms/user/cms/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUser',
    },
  },
  // お知らせ
  {
    path: '/cms/contents/fields/copy/:parent_field_id',
    name: 'cmsContentsFiledsCopy',
    component: () => import('../views/cms/contents/fields/Copy.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsNews',
    },
  },
  {
    path: '/cms/news',
    name: 'cmsNews',
    component: () => import('../views/cms/News.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsNews',
    },
  },
  // 申請タイプ管理
  {
    path: '/cms/contents/applications',
    name: 'cmsContentsApplicationList',
    component: () => import('../views/cms/contents/applications/List.vue'),
    meta: {
      page: 'cms',
    },
  },
  {
    path: '/cms/contents/applications/create',
    name: 'cmsContentsApplicationCreate',
    component: () => import('../views/cms/contents/applications/Edit.vue'),
    meta: {
      page: 'cms',
    },
  },
  {
    path: '/cms/contents/applications/edit/:apptypeId',
    name: 'cmsContentsApplicationEdit',
    component: () => import('../views/cms/contents/applications/Edit.vue'),
    meta: {
      page: 'cms',
    },
  },
  // 一般ユーザー管理
  {
    path: '/cms/user/gas/list',
    name: 'cmsUserGasList',
    component: () => import('../views/cms/user/gas/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUserGas',
    },
  },
  // 申請管理 一覧
  {
    path: '/cms/applications/list',
    name: 'cmsApplicationsList',
    component: () => import('../views/cms/applications/applications/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  {
    path: '/cms/applications/list/:year/:apptypeId',
    name: 'cmsApplicationsListWithParam',
    component: () => import('../views/cms/applications/applications/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  {
    path: '/cms/applications/edit/:appId',
    name: 'cmsApplicationsEdit',
    component: () => import('../views/cms/applications/applications/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 申請管理 メール送信
  {
    path: '/cms/applications/mail',
    name: 'cmsApplicationsMail',
    component: () => import('../views/cms/applications/applications/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 選考評価担当割当
  {
    path: '/cms/applications/assign/list',
    name: 'cmsApplicationsAssignList',
    component: () => import('../views/cms/applications/assign/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  {
    path: '/cms/applications/assign/edit/:apptypeId',
    name: 'cmsApplicationsAssignEdit',
    component: () => import('../views/cms/applications/assign/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 変更履歴
  {
    path: '/cms/applications/history/list',
    name: 'cmsApplicationsHistoryList',
    component: () => import('../views/cms/applications/history/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 管理者設定
  {
    path: '/cms/setting',
    name: 'cmsSetting',
    component: () => import('../views/cms/user/setting/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsSetting',
    },
  },
  // 選考評価
  // 選考評価担当割当
  {
    path: '/cms/evaluation/list',
    name: 'cmsEvaluationList',
    component: () => import('../views/cms/evaluation/evaluation/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsEvaluation',
    },
  },
  {
    path: '/cms/evaluation/edit/:appId/:stageId',
    name: 'cmsEvaluationEdit',
    component: () => import('../views/cms/evaluation/evaluation/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsEvaluation',
    },
  },
  {
    path: '/cms/evaluation/view/failure/:appId/:stageId',
    name: 'cmsEvaluationViewFailure',
    component: () => import('../views/cms/evaluation/evaluation/Failure.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsEvaluation',
    },
  },
  // 合否判定 judgment
  {
    path: '/cms/applications/judgment/list',
    name: 'cmsApplicationsJudgmentList',
    component: () => import('../views/cms/applications/judgment/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  {
    path: '/cms/applications/judgment/edit/:appId/:stageId',
    name: 'cmsApplicationsJudgmentEdit',
    component: () => import('../views/cms/applications/judgment/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 追加資料管理
  {
    path: '/cms/applications/attachment/list',
    name: 'cmsApplicationsAttachmentList',
    component: () => import('../views/cms/applications/attachment/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  {
    path: '/cms/applications/attachment/edit/:appId',
    name: 'cmsApplicationsAttachmentEdit',
    component: () => import('../views/cms/applications/attachment/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 期間設定
  {
    path: '/cms/form/edit',
    name: 'cmsFormEdit',
    component: () => import('../views/cms/form/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsForm',
    },
  },
  // 報告管理 一覧
  {
    path: '/cms/reports/list',
    name: 'cmsReportsList',
    component: () => import('../views/cms/reports/reports/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  {
    path: '/cms/reports/list/:year/:seriesId',
    name: 'cmsReportsListWithParam',
    component: () => import('../views/cms/reports/reports/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  {
    path: '/cms/reports/edit/:repId',
    name: 'cmsReportsEdit',
    component: () => import('../views/cms/reports/reports/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  // 履歴 メール
  {
    path: '/cms/record/mail/list',
    name: 'cmsRecordMailList',
    component: () => import('../views/cms/record/mail/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsRecord',
    },
  },
];

/**
 * Storeに認証データがない場合に、ストレージからデータの取得を行う。
 */
const checkExistTokenFromStoreOrStrage = () => {
  let { token } = Store.state.auth;
  if (!Store.state.auth.token) {
    const jsonStorage = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY);
    const storage = jsonStorage !== null ? JSON.parse(jsonStorage) : {};
    const {
      user_id: userId,
      token: storageToken,
      login_id: loginId,
      userType,
      authes,
    } = storage;
    token = storageToken || null;
    const params = {
      user_id: userId,
      login_id: loginId,
      token,
      userType,
      authes,
    };
    Store.dispatch('auth/login', params);
  }

  return (token !== null && token !== '');
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(page => page.meta.isPublic);
  // ページ遷移時はajax系の二重操作防止のwaitingをオフにしてあげる
  Store.dispatch('page/offWaiting');
  // 次のページがpublic設定であれば、そのまま遷移する。
  if (isPublic) {
    Store.dispatch('page/create', to.meta.page);
    next();
    return;
  }

  // 次のページがpublicでなければ、トークンチェックを行う。
  const doneLogin = checkExistTokenFromStoreOrStrage();

  // ログインしていなければ要求ページのmeta情報に合わせた遷移を行う
  // メタ情報は、public, gas, cms, 未定義（事実上の404）の4種
  if (!doneLogin) {
    console.error('no login');
    // 要求されたページをstoreに保存し、ログイン後に用いる。
    Store.commit('page/setDirectUrl', to.path);
    Store.dispatch('page/create', to.meta.page);
    if (to.meta.page === 'cms') {
      next('/cms/login');
    } else {
      next('/login');
    }
    return;
  }

  // ログインしていて、かつ遷移先のURLが未定義の場合と、一般ユーザーが管理画面、管理ユーザーが一般画面を見ようとすると、ユーザータイプごとに振り分けたTOPへ移動する
  const { userType } = Store.state.auth;
  const metaOk = Auth.USER_META[userType].indexOf(to.meta.page) !== -1;
  if (typeof to.meta.page === 'undefined' || !metaOk) {
    // ログイン済の場合はユーザータイプごとに切り分ける
    const isAdmin = userType === Auth.USER_TYPE.CMS || userType === Auth.USER_TYPE.SYS;
    const path = isAdmin ? '/cms/top' : '/top';
    Store.dispatch('page/create', to.meta.page);
    next({ path });
    return;
  }

  Store.dispatch('page/create', to.meta.page);
  Store.dispatch('cmsUserSetting/setInitSetting');

  next();
});

export default router;
