<template>
  <b-container fluid>
    <b-row class="textData">
      <b-col sm="3" :class="labelGridClass">
        <label>{{ label }}</label>
      </b-col>
      <b-col :sm="sm" class="pl-20 textWrap">
        <span>{{ data }}</span>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

export default {
  name: 'ShowDataText',
  props: {
    label: {},
    data: {},
    sm: { type: String, default: '8' },
    labelGridClass: { default: 'labelWrap' },
  },
};
</script>

<style scoped>
/* 共通Style */
* {
  color: #333333;
}
label,
.labelWrap {
  width: 180px;
  max-width: 180px;
  font-weight: bold;
  white-space:pre-line;
}
.textData .labelWrap {
  margin-right: 32px;
}
.textData{
  align-items: center;
  margin:15px auto;
  word-break: break-all;
}
.textData label{
  align-items:center;
  margin:0;
}
.textData label{
  text-align: left;
}
.textWrap{
  text-align: left;
}
.pl-20{
  padding-left: 20px;
}
</style>
