import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdList: [],
  selectedRepIdList: [],

  currentPage: 1,
  perPage: 10,
  totalRows: 0,
  // フィルタリング条件保存
  search: {
    name: '',
    series: null,
    year: null,
    code: '',
    status: '',
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilter(state, param) {
      Vue.set(state.search, param.key, param.value);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    initFilter(state) {
      Vue.set(state.search, 'name', '');
      Vue.set(state.search, 'series', null);
      Vue.set(state.search, 'year', null);
      Vue.set(state.search, 'code', '');
      Vue.set(state.search, 'status', '');
      state.currentPage = 1;
      state.perPage = 10;
      state.totalRows = 0;
    },
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.id;
      });
      state.filterdIdList = filterdIdList;
    },
    removeSelectedAppid(state, repId) {
      const index = state.selectedRepIdList.indexOf(repId);
      if (index !== -1) {
        state.selectedRepIdList.splice(index, 1);
      }
    },
    addSelectedAppid(state, repId) {
      if (state.selectedRepIdList.indexOf(repId) === -1) {
        state.selectedRepIdList.push(repId);
      }
    },
    allSelectedAppid(state) {
      state.selectedRepIdList = state.filterdIdList.slice();
    },
    initSelectedAppid(state) {
      state.selectedRepIdList = [];
    },
  },
  actions: {
  },
};
