<template>
  <b-container fluid class="w-name itemWrap">
    <b-row class="inputGroup">
      <b-col sm="5" class="labelWrap">
        <div class="badgeWrap">
          <div v-if="isRequired" class="mr-2 badge badge-required">必須</div>
        </div>
        <div class="label"><label :class="labelSize">{{ label }}</label></div>
      </b-col>
      <b-col :class="(errors.left.length)?'errItem textWrap':'textWrap'" sm="3">
        <b-form-input
          :placeholder="placeholders.left"
          :size="size"
          :class="inputClass"
          :maxLength="maxLength"
          v-model="inputData1"
        >
        </b-form-input>
      </b-col>
      <b-col :class="(errors.right.length)?'errItem textWrap':'textWrap'" sm="3">
        <b-form-input
          :placeholder="placeholders.right"
          :size="size"
          :class="inputClass"
          :maxLength="maxLength"
          v-model="inputData2"
        >
        </b-form-input>
      </b-col>
    </b-row>
    <span :class="['error', 'fw-3', errSpanClass]" v-if="errors.left.length">
      {{ errors.left[0] }}
    </span>
    <span :class="['error', 'mt-10', 'fw-3', errSpanClass]" v-if="errors.right.length">
      {{ errors.right[0] }}
    </span>
  </b-container>
</template>
<script>

export default {
  name: 'NameFormText',
  props: {
    label: {},
    maxLength: {},
    itemStyle: {},
    inputClass: {},
    errors: {
      type: Object,
      default: () => ({
        left: '',
        right: '',
      }),
    },
    placeholders: {
      type: Object,
      default: () => ({
        left: '',
        right: '',
      }),
    },
    labelSize: { type: String, default: '' },
    size: { type: String, default: '' },
    type: { type: String, default: 'text' },
    isRequired: { type: Boolean, default: false },
    errSpanClass: { type: String, default: 'defaultPl' },
  },
  computed: {
    inputData1: {
      get() {
        return this.$attrs.left;
      },
      set(value) {
        this.$emit('update:left', value);
      },
    },
    inputData2: {
      get() {
        return this.$attrs.right;
      },
      set(value) {
        this.$emit('update:right', value);
      },
    },
  },
};
</script>

<style scoped>
/* 共通Style */
* {
  color: #333333;
}
.itemWrap{
  margin:15px 0;
  width: 800px;
}
.inputGroup{
  align-items: center;
  margin:0 auto;
}
label,
.labelWrap {
  width: 180px;
  max-width: 237px;
  font-weight: bold;
  white-space:pre-line;
}
input[type="text"]{
  width: 190px;
  height: 50px;
}
.labelWrap {
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.labelWrap .badgeWrap,
.labelWrap .label{
  display: inline-block;
}
.labelWrap .badgeWrap div{
  vertical-align: middle;
}
label{
  align-items:center;
  margin:0;
}
label{
  text-align: left;
}
.defaultPl {
  padding-left: 31.5%;
}
span {
  text-align: left;
}
.textWrap{
  max-width: 200px;
  display: table;
  padding-right: 0;
  padding-left: 20px;
}
.error{
  font-size: 12.8px;
  color: #dc3545;
  display: block;
  width: 582px;
  margin-left: 15px;
  margin-top: 3px;
}
</style>
<style>
.w-name .errItem input{
  border:solid 1px #dc3545!important;
}
</style>
