<template>
  <b-container fluid :class="(errMessage&&errMessage.length)?'errItem itemWrap':'itemWrap'">
    <b-row :class="itemStyle">
      <b-col class="labelWrap" :sm="labelSm">
        <label :class="labelSize">{{ label }}</label>
      </b-col>
      <b-col sm="2" class="text" v-if="text">{{ text }}</b-col>
      <b-col sm="7" class="p-20">
        <b-form-checkbox-group
          class="checkWrap"
          :checked="(value instanceof Array)?value:[value]"
          :options="options"
          :size="size"
          @change="$emit('input', $event)"
        ></b-form-checkbox-group>
      </b-col>
    </b-row>
    <span class="error fw-3" v-if="(errMessage&&errMessage.length)">
      {{ errMessage[0] }}
    </span>
  </b-container>
</template>
<script>

export default {
  name: 'FormCheckBox',
  props: {
    label: {},
    itemStyle: {},
    value: {},
    text: {},
    errMessage: { default: null },
    options: { type: Array },
    size: { type: String, default: 'lg' },
    labelSize: { type: String, default: '' },
    labelSm: { type: String, default: '3' },
  },
};
</script>
<style>
  .checkWrap .b-custom-control-lg span{
    display: block;
    margin-top: 3px;
  }
  .checkWrap label{
    width: 200px !important;
  }
  .checkWrap .custom-control-label::before{
    border-radius: 0!important;
    border: solid 2px #6E6E6E!important;
  }
  .checkWrap .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #122889;
  }
</style>
<style scoped>
/* 共通Style */
* {
  color: #333333;
}
.labelWrap label,
.labelWrap {
  width: 180px;
  max-width: 237px;
  font-weight: bold;
  white-space:pre-line;
}
.checkWrap{
  text-align: left;
}
.errItem input{
  border:solid 1px #dc3545;
}
.error{
  font-size: 12.8px;
  color: #dc3545;
  margin-top: 3px;
  margin-left: 15px;
}
/* ラベル + 下にテキストタイプ(itemStyle = "block") */
.block{
  display: block;
}
/* ラベル + 横並びテキストタイプ(itemStyle = "line") */
.line .labelWrap {
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.itemWrap{
  margin:15px 0;
}
.line{
  align-items: center;
  margin:0 auto;
}
.line .labelWrap label{
  align-items:center;
  margin:0;
}
.line .labelWrap label{
  text-align: left;
}
.line + span.error{
  padding-left: 31.5%;
  text-align: left;
}
input[disabled]{
  border:none !important;
  background-color: #F3F3F3;
}
.line .p-20{
  padding:0 20px;
}
.text {
  text-align: left;
  padding-left: 20px;
}
</style>
