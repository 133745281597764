import Vue from 'vue';
import { cloneDeep } from 'lodash';
import moment from 'moment';

const INIT_STAGE = {
  from: null,
  to: null,
};

const INIT_FILE = {
  uploadedFileKey: null, // ファイルの時のキー
  uploadedFileName: null,
  dbAssetId: null,
  dbFileName: null,
  do_delete_file: false,
  is_draggingl: false,
};

const INIT_DATA = {
  apptypeId: null,
  apptype: {
    application_announcement_datetime: null,
    open_datetime: null,
    close_datetime: null,
    result_announcement_datetime: null,
  },
  stages: [
    cloneDeep(INIT_STAGE),
  ],
  report: {
    report_announcement_datetime: null,
    open_datetime: null,
    close_datetime: null,
  },
  fileAppForm: cloneDeep(INIT_FILE),
  fileAcceptance: cloneDeep(INIT_FILE),
  fileCostList: cloneDeep(INIT_FILE),
  fileReport: cloneDeep(INIT_FILE),
  fileCostReport: cloneDeep(INIT_FILE),
  // 物理ファイル入れ
  realFiles: {},
  isUseGroup: false,
};

function getEndTime(datetime) {
  if (!datetime) {
    return datetime;
  }
  const dt = moment(datetime);
  const ymdh = dt.add(1, 'hour').format('YYYY-MM-DD HH');
  return `${ymdh}:00:00`;
}

export default {
  namespaced: true,
  state: cloneDeep(INIT_DATA),
  mutations: {
    setDbData(state, dbData) {
      if (dbData === null || dbData.length === 0) {
        return;
      }
      state.apptypeId = dbData.id;
      const apptype = {
        application_announcement_datetime: dbData.application_announcement_datetime,
        open_datetime: dbData.open_datetime,
        close_datetime: getEndTime(dbData.close_datetime),
        result_announcement_datetime: dbData.result_announcement_datetime,
      };
      state.apptype = apptype;

      if (dbData.report_type) {
        const report = {
          report_announcement_datetime: dbData.report_type.report_announcement_datetime,
          open_datetime: dbData.report_type.open_datetime,
          close_datetime: getEndTime(dbData.report_type.close_datetime),
        };
        state.report = report;
      }

      if (dbData.stages.length === 0) {
        return;
      }
      // 評価段階の最大数分だけ入れ物作成（データ上は一次審査なしだったりするので）
      const maxStage = Math.max.apply(null, dbData.stages.map((stage) => { return stage.stage; }));
      for (let i = 1; i < maxStage; i += 1) {
        state.stages.push(cloneDeep(INIT_STAGE));
      }
      dbData.stages.forEach((stage) => {
        const index = stage.stage - 1;
        Vue.set(state.stages[index], 'from', stage.from);
        Vue.set(state.stages[index], 'to', getEndTime(stage.to));
      });
      state.isUseGroup = dbData.isUseGroup;
    },
    setDbFileData(state, dbData) {
      if (dbData.length === 0) {
        return;
      }
      dbData.forEach((data) => {
        if (data.file_type === 1) {
          state.fileAppForm.dbFileName = data.name;
          state.fileAppForm.dbAssetId = data.id;
        }
        if (data.file_type === 2) {
          state.fileAcceptance.dbFileName = data.name;
          state.fileAcceptance.dbAssetId = data.id;
        }
        if (data.file_type === 3) {
          state.fileCostList.dbFileName = data.name;
          state.fileCostList.dbAssetId = data.id;
        }
      });
    },
    setDbFileData4Report(state, dbData) {
      if (dbData.length === 0) {
        return;
      }
      dbData.forEach((data) => {
        if (data.file_type === 1) {
          state.fileReport.dbFileName = data.name;
          state.fileReport.dbAssetId = data.id;
        }
        if (data.file_type === 2) {
          state.fileCostReport.dbFileName = data.name;
          state.fileCostReport.dbAssetId = data.id;
        }
      });
    },
    setValue(state, param) {
      const { type, key, value } = param;
      state[type][key] = value;
    },
    updateApptypeAnnouncementDatetime(state, value) {
      Vue.set(state.apptype, 'application_announcement_datetime', value);
    },
    updateApptypeOpenDatetime(state, value) {
      Vue.set(state.apptype, 'open_datetime', value);
    },
    updateApptypeCloseDatetime(state, value) {
      Vue.set(state.apptype, 'close_datetime', value);
    },
    updateApptypeResultAnnouncementDatetime(state, value) {
      Vue.set(state.apptype, 'result_announcement_datetime', value);
    },
    updateReportAnnouncementDatetime(state, value) {
      Vue.set(state.report, 'report_announcement_datetime', value);
    },
    updateReportOpenDatetime(state, value) {
      Vue.set(state.report, 'open_datetime', value);
    },
    updateReportCloseDatetime(state, value) {
      Vue.set(state.report, 'close_datetime', value);
    },
    init(state) {
      state.apptypeId = null;
      Object.keys(state.apptype).forEach((key) => {
        state.apptype[key] = null;
      });
      state.stages = [cloneDeep(INIT_STAGE)];
      Object.keys(state.report).forEach((key) => {
        state.report[key] = null;
      });
      state.fileAppForm = cloneDeep(INIT_FILE);
      state.fileAcceptance = cloneDeep(INIT_FILE);
      state.fileCostList = cloneDeep(INIT_FILE);
      state.fileReport = cloneDeep(INIT_FILE);
      state.fileCostReport = cloneDeep(INIT_FILE);
      // 物理ファイル入れ
      state.realFiles = {};
      state.isUseGroup = false;
    },
    updateStageFrom(state, param) {
      Vue.set(state.stages[param.index], 'from', param.value);
    },
    updateStageTo(state, param) {
      Vue.set(state.stages[param.index], 'to', param.value);
    },
    updateGroupFlg(state, value) {
      state.isUseGroup = value;
    },
    addStage(state) {
      const newStage = cloneDeep(INIT_STAGE);
      state.stages.push(newStage);
    },
    removeStage(state, index) {
      state.stages.splice(index, 1);
    },
    // ファイル
    uploadFile(state, param) {
      const data = cloneDeep(INIT_FILE);
      data.uploadedFileKey = param.uploadedFileKey;
      data.uploadedFileName = param.uploadedFileName;
      state[param.uploadedFileKey] = data;
      // 物理ファイル
      Vue.set(state.realFiles, param.uploadedFileKey, param.realFile);
    },
  },
  actions: {
  },
};
