<template>
  <div
    :class="[
      'navWrap',
      isLogin && !isNoneMenu ? 'isLogin' : '',
    ]"
    :style="{ 'background':' url(' + headerBg + ') no-repeat center top' }">
    <b-navbar
      class="navBar px-0"
      align="left"
      fill="true">
      <b-navbar-brand class="noLink" href="#">
        <img src="../assets/img/group-logo.png">
      </b-navbar-brand>
      <b-navbar-brand
        :class="isNoneMenu?'noLink':''"
        href="#"
        @click="goZaidan">
        <img src="../assets/img/group-name.png">
      </b-navbar-brand>
     <b-navbar-brand
        href="#"
        @click="goTop"
        :class="isNoneMenu?'noLink systemLogo bold':'systemLogo bold'">
        助成業務支援システム
      </b-navbar-brand>
    </b-navbar>

    <div class="menuWrap" v-if="isLogin && !isNoneMenu">
      <CmsHeader
        v-if="page==='cms'"
        :menuName.sync="nowMenuName"
        :goInit="goInit"
        @kickLogout="logout"/>
      <GasHeader
        v-if="page==='gas'"
        :menuName.sync="nowMenuName"
        :goInit="goInit"
        @kickLogout="logout"/>
    </div>
  </div>
</template>

<script>
import CmsHeader from '@/components/cms/Header.vue';
import GasHeader from '@/components/gas/Header.vue';
import headerBg from '@/assets/img/bg_header_01.gif';

export default {
  name: 'Header',
  components: {
    CmsHeader, // eslint-disable-line
    GasHeader, // eslint-disable-line
  },
  props: {
  },
  data() {
    return {
      headerBg,
    };
  },
  methods: {
    async logout() {
      const routeName = this.page === 'cms' ? 'cmsLogin' : 'gasLogin';
      // next(false)の場合は、そのfalseをキャッチして、ログアウト処理を走らせない
      const result = await this.$router.push({ name: routeName })
        .catch((error) => {
          return error;
        });
      if (result) {
        this.$store.dispatch('auth/logout');
      }
    },
    goTop() {
      if (this.isInit || this.isNoneMenu) {
        return;
      }
      let routeName = '';
      if (this.$route.meta.back) {
        routeName = this.$route.meta.back;
      } else if (!this.isLogin) {
        routeName = this.page === 'cms' ? 'cmsLogin' : 'gasLogin';
      } else {
        routeName = this.page === 'cms' ? 'cmsTop' : 'gasTop';
      }
      this.$router.push({ name: routeName });
    },
    goSei() {
      window.open('https://sei.co.jp/');
    },
    goZaidan() {
      if (this.isNoneMenu) {
        return;
      }
      window.open('https://www.sei-group-csr.or.jp/');
    },
    goInit(path, store) {
      this.$store.commit(`${store}/initFilter`);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
  computed: {
    page() {
      return this.$store.state.page.view;
    },
    nowMenuName() {
      return this.$route.meta.menu;
    },
    isNoneMenu() {
      return this.$route.name === 'gasApplicationPrint';
    },
    isLogin() {
      return this.$store.state.auth.login_id !== null;
    },
    isInit() {
      const isTop = this.$route.name === 'gasTop' || this.$route.name === 'cmsTop';
      const isLogin = this.$route.name === 'gasLogin' || this.$route.name === 'cmsLogin';
      return isTop || isLogin;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .navWrap {
    width: 100%;
    /* 本家ではmax-width: 950pxであたってたが、全部に当てると大画面でも余白ができない */
    background-position: -2028px 0;
    color: #fff !important;
    min-width: 1280px;
    height: 77px;
  }
  .navWrap.isLogin {
    height: 117px;
  }

  .navBar {
    /* ここにバックグラウンドカラーを当ててもビルドした時に反映されない */
    min-width: 1280px;
    max-width: 1280px;
    height: 77px;
    margin: 0 auto;
  }

  .menuWrap {
    height: 40px;
    background-color: #122889;
    margin-left: auto;
  }

  .menuLabel {
    height: 40px;
    min-width: 1280px;
    max-width: 1280px;
    margin: 0 auto;
    border-width: 0 1px 0 1px;
    border-color: #7582bb;
    border-style: solid;
  }

  .systemLogo {
    position: absolute;
    right: 0;
    color: #fff !important;
  }

  .noLink:hover {
    cursor: default;
  }
</style>
